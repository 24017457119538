






import Vue from "vue";
import BaseOrders from "./BaseOrders.vue";

export default Vue.extend({
  components: {
    BaseOrders,
  },
  data() {
    return {};
  },
});
